const contractQuery = {
  state: {
    contractQuery: { pageNumber: 1, pageSize: 15, status: [], contractCategory: [] },
    contractChangeQuery: { pageNumber: 1, pageSize: 15, status: [] },
    invoiceQuery: { pageSize: 15, pageNumber: 1, status: [], invoiceProgress: 0, subjectTypeList: JSON.stringify([1, 3]) },
    agreementQuery: { pageNumber: 1, pageSize: 15, subjectType: 4, status: [] },
    agreementChangeQuery: { pageNumber: 1, pageSize: 15, subjectType: 4, status: [] },
    statementQuery: { pageNumber: 1, pageSize: 15, status: [] },
    statementChangeQuery: { pageNumber: 1, pageSize: 15, status: [] },
    statementInvoiceQuery: { pageNumber: 1, pageSize: 15, status: [], invoiceProgress: 0, subjectTypeList: JSON.stringify([2]) }
  },
  mutations: {
    set_query_contractQuery (state, data) {
      state.contractQuery = data
    },
    set_query_contractChangeQuery (state, data) {
      state.contractChangeQuery = data
    },
    set_query_invoiceQuery (state, data) {
      state.invoiceQuery = data
    },
    set_query_agreementQuery (state, data) {
      state.agreementQuery = data
    },
    set_query_agreementChangeQuery (state, data) {
      state.agreementChangeQuery = data
    },
    set_query_statementQuery (state, data) {
      state.statementQuery = data
    },
    set_query_statementChangeQuery (state, data) {
      state.statementChangeQuery = data
    },
    set_query_statementInvoiceQuery (state, data) {
      state.statementInvoiceQuery = data
    }
  },
  getters: {

  },
  actions: {

  }
}

export default contractQuery
